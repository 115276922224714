$dark-color: #343a40;
$muted-color: #6c757d;
$white-color: #FFFFFF;
$error-color: #DB0D4C;
$primary-color: #3FA7E1;
$secondary-color: #A96AB5;
$success-color: #45D386;
$input-border-primary-color: $primary-color;
$gradient-primary-left-right: linear-gradient(to right, #14E8D5, #597EE8);
$gradient-primary-top-left-bottom-right: linear-gradient(to bottom right, #14E8D5, #597EE8);
$gradient-secondary-left-right: linear-gradient(to right, #B06AB3, #4568DC);
$gradient-secondary-top-left-bottom-right: linear-gradient(to bottom right, #4568DC, #B06AB3);