.ant-input,
.ant-select .ant-select-selection__rendered {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;

  &.ant-select-selection__rendered {
    margin: 0rem;

    .ant-select-selection__placeholder {
      left: 1.5rem !important;
    }
  }
}

.ant-select,
.ant-calendar-picker {
  i,
  span.ant-select-arrow,
  span.ant-select-selection__clear {
    display: block;
    right: 1.5rem !important;
  }
}

.ant-form-item-with-help.ant-form-item {
  margin-bottom: 0.5rem !important;

  .ant-form-explain {
    text-align: left;
    white-space: nowrap;
    padding-top: 0.25rem;
  }
}

.ant-modal.ant-modal-danger {
  background: #F7D7EB !important;
  border: 1.5px solid $error-color !important;
  border-radius: 5px !important;
  padding-bottom: 0px !important;
}

.ant-modal-danger .ant-modal-content {
  background-color: transparent !important;
  border-radius: 0px !important;
}

.ant-btn.ant-btn-danger {
  color: $white-color !important;
  background-color: $error-color !important;
}

.ant-modal-close-icon {
  color: $error-color !important;
  font-size: 21px;
}

.ant-table-body {
  overflow-x: auto;
  padding: 0rem 0.25rem;
  
  table {
    border-spacing: 0rem 1rem !important;
    border-collapse: separate !important;
  }
}

.ant-table-thead > tr > th {
  font-size: 16px;
  white-space: nowrap;
  color: #3fa7e1 !important;
  font-weight: bold !important;
  border-bottom: unset !important;
  background-color: transparent !important;
}

.ant-table-tbody {
  tr {
    border-radius: 0.25rem;
    box-shadow: 0px 2px 4px 0px rgba(38, 35, 66, 0.25);
  }
}

// Specific input for AMDIN only
.ant-input-affix-wrapper {
  &.border-0 input.ant-input {
    border: none;

    &:focus {
      box-shadow: none;
    }
  }

  &.border-secondary input.ant-input {
    border: 1px solid #A96AB5;

    &::placeholder {
      color: #A96AB5;
    }

    &:focus {
      box-shadow: 0 0 0 2px rgba(169, 106, 181, 0.2);
    }
  }

  &.text-secondary input.ant-input {
    color: #A96AB5;

    &::placeholder {
      color: #A96AB5;
    }
  }
}