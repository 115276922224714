@import "@/assets/styles/color-variable.scss";

.navbar {
  height: auto;
  padding: 1rem;

  .navbar-brand {
    margin: 0rem;
    padding: 0rem;
    display: flex;
    align-items: flex-end;

    .navbar-brand-logo {
      width: 100px;
      padding-right: 0.5rem;
    }

    .navbar-brand-name {
      font-weight: 500;
      color: $dark-color;
    }
  }
  
  .navbar-collapse {
    .navbar-nav{
      display: flex;
      margin-left: auto;
      align-items: center;

      .nav-item {
        font-weight: 500;
        padding: 0.5rem 1rem;
        text-transform: uppercase;

        a {
          color: $dark-color;
          
          &.active {
            color: $primary-color;
          }
        }
      }

      @media (max-width: 991px) {
        align-items: flex-start;

        .nav-item {
          padding: 0.75rem 0rem 0rem 0rem !important;
        }
      }
    }
  }
}

.header-menu-dropdown {
  .ant-dropdown-menu {
    padding: 0;
    width: 300px;
    overflow: hidden;
    margin-top: 0.5rem;
    border-radius: 0.5rem;
    background-image: $gradient-primary-left-right !important;

    .ant-dropdown-menu-item {
      color: $white-color;
      padding: 0.25rem 1rem;
      transition: all ease-in-out 0.25s;

      a {
        color: $white-color;
      }

      &:hover,
      a:hover {
        color: $primary-color;
      }

      &:last-child {
        padding-bottom: 0.5rem;
      }
    }
  }
}

.ant-dropdown-trigger {
  transition: all ease-in-out 0.25s;
  
  &.ant-dropdown-open {
    color: $white-color !important;
    transition: all ease-in-out 0.25s;
    background-image: $gradient-primary-left-right !important;
  }
}

button:focus {
  outline: unset;
}