@import url(https://fonts.googleapis.com/css?family=Kantumruy|Ubuntu&display=swap);
.ant-input,
.ant-select .ant-select-selection__rendered {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important; }
  .ant-input.ant-select-selection__rendered,
  .ant-select .ant-select-selection__rendered.ant-select-selection__rendered {
    margin: 0rem; }
    .ant-input.ant-select-selection__rendered .ant-select-selection__placeholder,
    .ant-select .ant-select-selection__rendered.ant-select-selection__rendered .ant-select-selection__placeholder {
      left: 1.5rem !important; }

.ant-select i,
.ant-select span.ant-select-arrow,
.ant-select span.ant-select-selection__clear,
.ant-calendar-picker i,
.ant-calendar-picker span.ant-select-arrow,
.ant-calendar-picker span.ant-select-selection__clear {
  display: block;
  right: 1.5rem !important; }

.ant-form-item-with-help.ant-form-item {
  margin-bottom: 0.5rem !important; }
  .ant-form-item-with-help.ant-form-item .ant-form-explain {
    text-align: left;
    white-space: nowrap;
    padding-top: 0.25rem; }

.ant-modal.ant-modal-danger {
  background: #F7D7EB !important;
  border: 1.5px solid #DB0D4C !important;
  border-radius: 5px !important;
  padding-bottom: 0px !important; }

.ant-modal-danger .ant-modal-content {
  background-color: transparent !important;
  border-radius: 0px !important; }

.ant-btn.ant-btn-danger {
  color: #FFFFFF !important;
  background-color: #DB0D4C !important; }

.ant-modal-close-icon {
  color: #DB0D4C !important;
  font-size: 21px; }

.ant-table-body {
  overflow-x: auto;
  padding: 0rem 0.25rem; }
  .ant-table-body table {
    border-spacing: 0rem 1rem !important;
    border-collapse: separate !important; }

.ant-table-thead > tr > th {
  font-size: 16px;
  white-space: nowrap;
  color: #3fa7e1 !important;
  font-weight: bold !important;
  border-bottom: unset !important;
  background-color: transparent !important; }

.ant-table-tbody tr {
  border-radius: 0.25rem;
  box-shadow: 0px 2px 4px 0px rgba(38, 35, 66, 0.25); }

.ant-input-affix-wrapper.border-0 input.ant-input {
  border: none; }
  .ant-input-affix-wrapper.border-0 input.ant-input:focus {
    box-shadow: none; }

.ant-input-affix-wrapper.border-secondary input.ant-input {
  border: 1px solid #A96AB5; }
  .ant-input-affix-wrapper.border-secondary input.ant-input::-webkit-input-placeholder {
    color: #A96AB5; }
  .ant-input-affix-wrapper.border-secondary input.ant-input::-ms-input-placeholder {
    color: #A96AB5; }
  .ant-input-affix-wrapper.border-secondary input.ant-input::placeholder {
    color: #A96AB5; }
  .ant-input-affix-wrapper.border-secondary input.ant-input:focus {
    box-shadow: 0 0 0 2px rgba(169, 106, 181, 0.2); }

.ant-input-affix-wrapper.text-secondary input.ant-input {
  color: #A96AB5; }
  .ant-input-affix-wrapper.text-secondary input.ant-input::-webkit-input-placeholder {
    color: #A96AB5; }
  .ant-input-affix-wrapper.text-secondary input.ant-input::-ms-input-placeholder {
    color: #A96AB5; }
  .ant-input-affix-wrapper.text-secondary input.ant-input::placeholder {
    color: #A96AB5; }

#root {
  height: 100%; }

body {
  font-family: "Ubuntu", "Kantumruy" !important; }

.content-center-screen {
  top: 50%;
  left: 50%;
  width: 100%;
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.font-weight-bold {
  font-weight: 500 !important; }

.text-primary {
  color: #3fa7e1 !important; }

.text-secondary {
  color: #A96AB5 !important; }

.text-success {
  color: #45d386 !important; }

.text-danger {
  color: #db0d4c !important; }

.text-shadow {
  text-shadow: rgba(0, 0, 0, 0.25) 1px 1px 1px !important; }

.border-primary {
  border-color: #3fa7e1 !important; }

.border-secondary {
  border-color: #A96AB5 !important; }

.border-success {
  border-color: #45d386 !important; }

.border-danger {
  border-color: #db0d4c !important; }

.border-1 {
  border: 1px solid; }

.border-2 {
  border: 2px solid; }

.border-3 {
  border: 3px solid; }

.border-4 {
  border: 4px solid; }

.border-5 {
  border: 5px solid; }

.border-bottom-1 {
  border-bottom: 1px solid #3fa7e1 !important; }

.border-bottom-2 {
  border-bottom: 2px solid #3fa7e1 !important; }

.border-bottom-3 {
  border-bottom: 3px solid #3fa7e1 !important; }

.border-bottom-4 {
  border-bottom: 4px solid #3fa7e1 !important; }

.border-bottom-5 {
  border-bottom: 5px solid #3fa7e1 !important; }

.border-radius-1 {
  border-radius: 1px !important; }

.border-radius-2 {
  border-radius: 2px !important; }

.border-radius-3 {
  border-radius: 3px !important; }

.border-radius-4 {
  border-radius: 4px !important; }

.border-radius-5 {
  border-radius: 5px !important; }

.border-radius-6 {
  border-radius: 6px !important; }

.border-radius-7 {
  border-radius: 7px !important; }

.border-radius-8 {
  border-radius: 8px !important; }

.border-radius-9 {
  border-radius: 9px !important; }

.border-radius-10 {
  border-radius: 10px !important; }

.border-radius-11 {
  border-radius: 11px !important; }

.border-radius-12 {
  border-radius: 12px !important; }

.border-radius-13 {
  border-radius: 13px !important; }

.border-radius-14 {
  border-radius: 14px !important; }

.border-radius-15 {
  border-radius: 15px !important; }

.border-radius-16 {
  border-radius: 16px !important; }

.border-radius-17 {
  border-radius: 17px !important; }

.border-radius-18 {
  border-radius: 18px !important; }

.border-radius-19 {
  border-radius: 19px !important; }

.border-radius-20 {
  border-radius: 20px !important; }

.border-radius-21 {
  border-radius: 21px !important; }

.border-radius-22 {
  border-radius: 22px !important; }

.border-radius-23 {
  border-radius: 23px !important; }

.border-radius-24 {
  border-radius: 24px !important; }

.border-radius-25 {
  border-radius: 25px !important; }

.font-1 {
  font-size: 1px !important; }

.font-2 {
  font-size: 2px !important; }

.font-3 {
  font-size: 3px !important; }

.font-4 {
  font-size: 4px !important; }

.font-5 {
  font-size: 5px !important; }

.font-6 {
  font-size: 6px !important; }

.font-7 {
  font-size: 7px !important; }

.font-8 {
  font-size: 8px !important; }

.font-9 {
  font-size: 9px !important; }

.font-10 {
  font-size: 10px !important; }

.font-11 {
  font-size: 11px !important; }

.font-12 {
  font-size: 12px !important; }

.font-13 {
  font-size: 13px !important; }

.font-14 {
  font-size: 14px !important; }

.font-15 {
  font-size: 15px !important; }

.font-16 {
  font-size: 16px !important; }

.font-17 {
  font-size: 17px !important; }

.font-18 {
  font-size: 18px !important; }

.font-19 {
  font-size: 19px !important; }

.font-20 {
  font-size: 20px !important; }

.font-21 {
  font-size: 21px !important; }

.font-22 {
  font-size: 22px !important; }

.font-23 {
  font-size: 23px !important; }

.font-24 {
  font-size: 24px !important; }

.font-25 {
  font-size: 25px !important; }

.font-26 {
  font-size: 26px !important; }

.font-27 {
  font-size: 27px !important; }

.font-28 {
  font-size: 28px !important; }

.font-29 {
  font-size: 29px !important; }

.font-30 {
  font-size: 30px !important; }

.font-31 {
  font-size: 31px !important; }

.font-32 {
  font-size: 32px !important; }

.font-33 {
  font-size: 33px !important; }

.font-34 {
  font-size: 34px !important; }

.font-35 {
  font-size: 35px !important; }

.font-36 {
  font-size: 36px !important; }

.font-37 {
  font-size: 37px !important; }

.font-38 {
  font-size: 38px !important; }

.font-39 {
  font-size: 39px !important; }

.font-40 {
  font-size: 40px !important; }

.font-41 {
  font-size: 41px !important; }

.font-42 {
  font-size: 42px !important; }

.font-43 {
  font-size: 43px !important; }

.font-44 {
  font-size: 44px !important; }

.font-45 {
  font-size: 45px !important; }

.font-46 {
  font-size: 46px !important; }

.font-47 {
  font-size: 47px !important; }

.font-48 {
  font-size: 48px !important; }

.font-49 {
  font-size: 49px !important; }

.font-50 {
  font-size: 50px !important; }

.width-1 {
  width: 1px; }

.height-1 {
  height: 1px; }

.width-2 {
  width: 2px; }

.height-2 {
  height: 2px; }

.width-3 {
  width: 3px; }

.height-3 {
  height: 3px; }

.width-4 {
  width: 4px; }

.height-4 {
  height: 4px; }

.width-5 {
  width: 5px; }

.height-5 {
  height: 5px; }

.width-6 {
  width: 6px; }

.height-6 {
  height: 6px; }

.width-7 {
  width: 7px; }

.height-7 {
  height: 7px; }

.width-8 {
  width: 8px; }

.height-8 {
  height: 8px; }

.width-9 {
  width: 9px; }

.height-9 {
  height: 9px; }

.width-10 {
  width: 10px; }

.height-10 {
  height: 10px; }

.width-11 {
  width: 11px; }

.height-11 {
  height: 11px; }

.width-12 {
  width: 12px; }

.height-12 {
  height: 12px; }

.width-13 {
  width: 13px; }

.height-13 {
  height: 13px; }

.width-14 {
  width: 14px; }

.height-14 {
  height: 14px; }

.width-15 {
  width: 15px; }

.height-15 {
  height: 15px; }

.width-16 {
  width: 16px; }

.height-16 {
  height: 16px; }

.width-17 {
  width: 17px; }

.height-17 {
  height: 17px; }

.width-18 {
  width: 18px; }

.height-18 {
  height: 18px; }

.width-19 {
  width: 19px; }

.height-19 {
  height: 19px; }

.width-20 {
  width: 20px; }

.height-20 {
  height: 20px; }

.width-21 {
  width: 21px; }

.height-21 {
  height: 21px; }

.width-22 {
  width: 22px; }

.height-22 {
  height: 22px; }

.width-23 {
  width: 23px; }

.height-23 {
  height: 23px; }

.width-24 {
  width: 24px; }

.height-24 {
  height: 24px; }

.width-25 {
  width: 25px; }

.height-25 {
  height: 25px; }

.width-26 {
  width: 26px; }

.height-26 {
  height: 26px; }

.width-27 {
  width: 27px; }

.height-27 {
  height: 27px; }

.width-28 {
  width: 28px; }

.height-28 {
  height: 28px; }

.width-29 {
  width: 29px; }

.height-29 {
  height: 29px; }

.width-30 {
  width: 30px; }

.height-30 {
  height: 30px; }

.width-31 {
  width: 31px; }

.height-31 {
  height: 31px; }

.width-32 {
  width: 32px; }

.height-32 {
  height: 32px; }

.width-33 {
  width: 33px; }

.height-33 {
  height: 33px; }

.width-34 {
  width: 34px; }

.height-34 {
  height: 34px; }

.width-35 {
  width: 35px; }

.height-35 {
  height: 35px; }

.width-36 {
  width: 36px; }

.height-36 {
  height: 36px; }

.width-37 {
  width: 37px; }

.height-37 {
  height: 37px; }

.width-38 {
  width: 38px; }

.height-38 {
  height: 38px; }

.width-39 {
  width: 39px; }

.height-39 {
  height: 39px; }

.width-40 {
  width: 40px; }

.height-40 {
  height: 40px; }

.width-41 {
  width: 41px; }

.height-41 {
  height: 41px; }

.width-42 {
  width: 42px; }

.height-42 {
  height: 42px; }

.width-43 {
  width: 43px; }

.height-43 {
  height: 43px; }

.width-44 {
  width: 44px; }

.height-44 {
  height: 44px; }

.width-45 {
  width: 45px; }

.height-45 {
  height: 45px; }

.width-46 {
  width: 46px; }

.height-46 {
  height: 46px; }

.width-47 {
  width: 47px; }

.height-47 {
  height: 47px; }

.width-48 {
  width: 48px; }

.height-48 {
  height: 48px; }

.width-49 {
  width: 49px; }

.height-49 {
  height: 49px; }

.width-50 {
  width: 50px; }

.height-50 {
  height: 50px; }

.width-51 {
  width: 51px; }

.height-51 {
  height: 51px; }

.width-52 {
  width: 52px; }

.height-52 {
  height: 52px; }

.width-53 {
  width: 53px; }

.height-53 {
  height: 53px; }

.width-54 {
  width: 54px; }

.height-54 {
  height: 54px; }

.width-55 {
  width: 55px; }

.height-55 {
  height: 55px; }

.width-56 {
  width: 56px; }

.height-56 {
  height: 56px; }

.width-57 {
  width: 57px; }

.height-57 {
  height: 57px; }

.width-58 {
  width: 58px; }

.height-58 {
  height: 58px; }

.width-59 {
  width: 59px; }

.height-59 {
  height: 59px; }

.width-60 {
  width: 60px; }

.height-60 {
  height: 60px; }

.width-61 {
  width: 61px; }

.height-61 {
  height: 61px; }

.width-62 {
  width: 62px; }

.height-62 {
  height: 62px; }

.width-63 {
  width: 63px; }

.height-63 {
  height: 63px; }

.width-64 {
  width: 64px; }

.height-64 {
  height: 64px; }

.width-65 {
  width: 65px; }

.height-65 {
  height: 65px; }

.width-66 {
  width: 66px; }

.height-66 {
  height: 66px; }

.width-67 {
  width: 67px; }

.height-67 {
  height: 67px; }

.width-68 {
  width: 68px; }

.height-68 {
  height: 68px; }

.width-69 {
  width: 69px; }

.height-69 {
  height: 69px; }

.width-70 {
  width: 70px; }

.height-70 {
  height: 70px; }

.width-71 {
  width: 71px; }

.height-71 {
  height: 71px; }

.width-72 {
  width: 72px; }

.height-72 {
  height: 72px; }

.width-73 {
  width: 73px; }

.height-73 {
  height: 73px; }

.width-74 {
  width: 74px; }

.height-74 {
  height: 74px; }

.width-75 {
  width: 75px; }

.height-75 {
  height: 75px; }

.width-76 {
  width: 76px; }

.height-76 {
  height: 76px; }

.width-77 {
  width: 77px; }

.height-77 {
  height: 77px; }

.width-78 {
  width: 78px; }

.height-78 {
  height: 78px; }

.width-79 {
  width: 79px; }

.height-79 {
  height: 79px; }

.width-80 {
  width: 80px; }

.height-80 {
  height: 80px; }

.width-81 {
  width: 81px; }

.height-81 {
  height: 81px; }

.width-82 {
  width: 82px; }

.height-82 {
  height: 82px; }

.width-83 {
  width: 83px; }

.height-83 {
  height: 83px; }

.width-84 {
  width: 84px; }

.height-84 {
  height: 84px; }

.width-85 {
  width: 85px; }

.height-85 {
  height: 85px; }

.width-86 {
  width: 86px; }

.height-86 {
  height: 86px; }

.width-87 {
  width: 87px; }

.height-87 {
  height: 87px; }

.width-88 {
  width: 88px; }

.height-88 {
  height: 88px; }

.width-89 {
  width: 89px; }

.height-89 {
  height: 89px; }

.width-90 {
  width: 90px; }

.height-90 {
  height: 90px; }

.width-91 {
  width: 91px; }

.height-91 {
  height: 91px; }

.width-92 {
  width: 92px; }

.height-92 {
  height: 92px; }

.width-93 {
  width: 93px; }

.height-93 {
  height: 93px; }

.width-94 {
  width: 94px; }

.height-94 {
  height: 94px; }

.width-95 {
  width: 95px; }

.height-95 {
  height: 95px; }

.width-96 {
  width: 96px; }

.height-96 {
  height: 96px; }

.width-97 {
  width: 97px; }

.height-97 {
  height: 97px; }

.width-98 {
  width: 98px; }

.height-98 {
  height: 98px; }

.width-99 {
  width: 99px; }

.height-99 {
  height: 99px; }

.width-100 {
  width: 100px; }

.height-100 {
  height: 100px; }

.width-101 {
  width: 101px; }

.height-101 {
  height: 101px; }

.width-102 {
  width: 102px; }

.height-102 {
  height: 102px; }

.width-103 {
  width: 103px; }

.height-103 {
  height: 103px; }

.width-104 {
  width: 104px; }

.height-104 {
  height: 104px; }

.width-105 {
  width: 105px; }

.height-105 {
  height: 105px; }

.width-106 {
  width: 106px; }

.height-106 {
  height: 106px; }

.width-107 {
  width: 107px; }

.height-107 {
  height: 107px; }

.width-108 {
  width: 108px; }

.height-108 {
  height: 108px; }

.width-109 {
  width: 109px; }

.height-109 {
  height: 109px; }

.width-110 {
  width: 110px; }

.height-110 {
  height: 110px; }

.width-111 {
  width: 111px; }

.height-111 {
  height: 111px; }

.width-112 {
  width: 112px; }

.height-112 {
  height: 112px; }

.width-113 {
  width: 113px; }

.height-113 {
  height: 113px; }

.width-114 {
  width: 114px; }

.height-114 {
  height: 114px; }

.width-115 {
  width: 115px; }

.height-115 {
  height: 115px; }

.width-116 {
  width: 116px; }

.height-116 {
  height: 116px; }

.width-117 {
  width: 117px; }

.height-117 {
  height: 117px; }

.width-118 {
  width: 118px; }

.height-118 {
  height: 118px; }

.width-119 {
  width: 119px; }

.height-119 {
  height: 119px; }

.width-120 {
  width: 120px; }

.height-120 {
  height: 120px; }

.width-121 {
  width: 121px; }

.height-121 {
  height: 121px; }

.width-122 {
  width: 122px; }

.height-122 {
  height: 122px; }

.width-123 {
  width: 123px; }

.height-123 {
  height: 123px; }

.width-124 {
  width: 124px; }

.height-124 {
  height: 124px; }

.width-125 {
  width: 125px; }

.height-125 {
  height: 125px; }

.width-126 {
  width: 126px; }

.height-126 {
  height: 126px; }

.width-127 {
  width: 127px; }

.height-127 {
  height: 127px; }

.width-128 {
  width: 128px; }

.height-128 {
  height: 128px; }

.width-129 {
  width: 129px; }

.height-129 {
  height: 129px; }

.width-130 {
  width: 130px; }

.height-130 {
  height: 130px; }

.width-131 {
  width: 131px; }

.height-131 {
  height: 131px; }

.width-132 {
  width: 132px; }

.height-132 {
  height: 132px; }

.width-133 {
  width: 133px; }

.height-133 {
  height: 133px; }

.width-134 {
  width: 134px; }

.height-134 {
  height: 134px; }

.width-135 {
  width: 135px; }

.height-135 {
  height: 135px; }

.width-136 {
  width: 136px; }

.height-136 {
  height: 136px; }

.width-137 {
  width: 137px; }

.height-137 {
  height: 137px; }

.width-138 {
  width: 138px; }

.height-138 {
  height: 138px; }

.width-139 {
  width: 139px; }

.height-139 {
  height: 139px; }

.width-140 {
  width: 140px; }

.height-140 {
  height: 140px; }

.width-141 {
  width: 141px; }

.height-141 {
  height: 141px; }

.width-142 {
  width: 142px; }

.height-142 {
  height: 142px; }

.width-143 {
  width: 143px; }

.height-143 {
  height: 143px; }

.width-144 {
  width: 144px; }

.height-144 {
  height: 144px; }

.width-145 {
  width: 145px; }

.height-145 {
  height: 145px; }

.width-146 {
  width: 146px; }

.height-146 {
  height: 146px; }

.width-147 {
  width: 147px; }

.height-147 {
  height: 147px; }

.width-148 {
  width: 148px; }

.height-148 {
  height: 148px; }

.width-149 {
  width: 149px; }

.height-149 {
  height: 149px; }

.width-150 {
  width: 150px; }

.height-150 {
  height: 150px; }

.width-151 {
  width: 151px; }

.height-151 {
  height: 151px; }

.width-152 {
  width: 152px; }

.height-152 {
  height: 152px; }

.width-153 {
  width: 153px; }

.height-153 {
  height: 153px; }

.width-154 {
  width: 154px; }

.height-154 {
  height: 154px; }

.width-155 {
  width: 155px; }

.height-155 {
  height: 155px; }

.width-156 {
  width: 156px; }

.height-156 {
  height: 156px; }

.width-157 {
  width: 157px; }

.height-157 {
  height: 157px; }

.width-158 {
  width: 158px; }

.height-158 {
  height: 158px; }

.width-159 {
  width: 159px; }

.height-159 {
  height: 159px; }

.width-160 {
  width: 160px; }

.height-160 {
  height: 160px; }

.width-161 {
  width: 161px; }

.height-161 {
  height: 161px; }

.width-162 {
  width: 162px; }

.height-162 {
  height: 162px; }

.width-163 {
  width: 163px; }

.height-163 {
  height: 163px; }

.width-164 {
  width: 164px; }

.height-164 {
  height: 164px; }

.width-165 {
  width: 165px; }

.height-165 {
  height: 165px; }

.width-166 {
  width: 166px; }

.height-166 {
  height: 166px; }

.width-167 {
  width: 167px; }

.height-167 {
  height: 167px; }

.width-168 {
  width: 168px; }

.height-168 {
  height: 168px; }

.width-169 {
  width: 169px; }

.height-169 {
  height: 169px; }

.width-170 {
  width: 170px; }

.height-170 {
  height: 170px; }

.width-171 {
  width: 171px; }

.height-171 {
  height: 171px; }

.width-172 {
  width: 172px; }

.height-172 {
  height: 172px; }

.width-173 {
  width: 173px; }

.height-173 {
  height: 173px; }

.width-174 {
  width: 174px; }

.height-174 {
  height: 174px; }

.width-175 {
  width: 175px; }

.height-175 {
  height: 175px; }

.width-176 {
  width: 176px; }

.height-176 {
  height: 176px; }

.width-177 {
  width: 177px; }

.height-177 {
  height: 177px; }

.width-178 {
  width: 178px; }

.height-178 {
  height: 178px; }

.width-179 {
  width: 179px; }

.height-179 {
  height: 179px; }

.width-180 {
  width: 180px; }

.height-180 {
  height: 180px; }

.width-181 {
  width: 181px; }

.height-181 {
  height: 181px; }

.width-182 {
  width: 182px; }

.height-182 {
  height: 182px; }

.width-183 {
  width: 183px; }

.height-183 {
  height: 183px; }

.width-184 {
  width: 184px; }

.height-184 {
  height: 184px; }

.width-185 {
  width: 185px; }

.height-185 {
  height: 185px; }

.width-186 {
  width: 186px; }

.height-186 {
  height: 186px; }

.width-187 {
  width: 187px; }

.height-187 {
  height: 187px; }

.width-188 {
  width: 188px; }

.height-188 {
  height: 188px; }

.width-189 {
  width: 189px; }

.height-189 {
  height: 189px; }

.width-190 {
  width: 190px; }

.height-190 {
  height: 190px; }

.width-191 {
  width: 191px; }

.height-191 {
  height: 191px; }

.width-192 {
  width: 192px; }

.height-192 {
  height: 192px; }

.width-193 {
  width: 193px; }

.height-193 {
  height: 193px; }

.width-194 {
  width: 194px; }

.height-194 {
  height: 194px; }

.width-195 {
  width: 195px; }

.height-195 {
  height: 195px; }

.width-196 {
  width: 196px; }

.height-196 {
  height: 196px; }

.width-197 {
  width: 197px; }

.height-197 {
  height: 197px; }

.width-198 {
  width: 198px; }

.height-198 {
  height: 198px; }

.width-199 {
  width: 199px; }

.height-199 {
  height: 199px; }

.width-200 {
  width: 200px; }

.height-200 {
  height: 200px; }

.width-201 {
  width: 201px; }

.height-201 {
  height: 201px; }

.width-202 {
  width: 202px; }

.height-202 {
  height: 202px; }

.width-203 {
  width: 203px; }

.height-203 {
  height: 203px; }

.width-204 {
  width: 204px; }

.height-204 {
  height: 204px; }

.width-205 {
  width: 205px; }

.height-205 {
  height: 205px; }

.width-206 {
  width: 206px; }

.height-206 {
  height: 206px; }

.width-207 {
  width: 207px; }

.height-207 {
  height: 207px; }

.width-208 {
  width: 208px; }

.height-208 {
  height: 208px; }

.width-209 {
  width: 209px; }

.height-209 {
  height: 209px; }

.width-210 {
  width: 210px; }

.height-210 {
  height: 210px; }

.width-211 {
  width: 211px; }

.height-211 {
  height: 211px; }

.width-212 {
  width: 212px; }

.height-212 {
  height: 212px; }

.width-213 {
  width: 213px; }

.height-213 {
  height: 213px; }

.width-214 {
  width: 214px; }

.height-214 {
  height: 214px; }

.width-215 {
  width: 215px; }

.height-215 {
  height: 215px; }

.width-216 {
  width: 216px; }

.height-216 {
  height: 216px; }

.width-217 {
  width: 217px; }

.height-217 {
  height: 217px; }

.width-218 {
  width: 218px; }

.height-218 {
  height: 218px; }

.width-219 {
  width: 219px; }

.height-219 {
  height: 219px; }

.width-220 {
  width: 220px; }

.height-220 {
  height: 220px; }

.width-221 {
  width: 221px; }

.height-221 {
  height: 221px; }

.width-222 {
  width: 222px; }

.height-222 {
  height: 222px; }

.width-223 {
  width: 223px; }

.height-223 {
  height: 223px; }

.width-224 {
  width: 224px; }

.height-224 {
  height: 224px; }

.width-225 {
  width: 225px; }

.height-225 {
  height: 225px; }

.width-226 {
  width: 226px; }

.height-226 {
  height: 226px; }

.width-227 {
  width: 227px; }

.height-227 {
  height: 227px; }

.width-228 {
  width: 228px; }

.height-228 {
  height: 228px; }

.width-229 {
  width: 229px; }

.height-229 {
  height: 229px; }

.width-230 {
  width: 230px; }

.height-230 {
  height: 230px; }

.width-231 {
  width: 231px; }

.height-231 {
  height: 231px; }

.width-232 {
  width: 232px; }

.height-232 {
  height: 232px; }

.width-233 {
  width: 233px; }

.height-233 {
  height: 233px; }

.width-234 {
  width: 234px; }

.height-234 {
  height: 234px; }

.width-235 {
  width: 235px; }

.height-235 {
  height: 235px; }

.width-236 {
  width: 236px; }

.height-236 {
  height: 236px; }

.width-237 {
  width: 237px; }

.height-237 {
  height: 237px; }

.width-238 {
  width: 238px; }

.height-238 {
  height: 238px; }

.width-239 {
  width: 239px; }

.height-239 {
  height: 239px; }

.width-240 {
  width: 240px; }

.height-240 {
  height: 240px; }

.width-241 {
  width: 241px; }

.height-241 {
  height: 241px; }

.width-242 {
  width: 242px; }

.height-242 {
  height: 242px; }

.width-243 {
  width: 243px; }

.height-243 {
  height: 243px; }

.width-244 {
  width: 244px; }

.height-244 {
  height: 244px; }

.width-245 {
  width: 245px; }

.height-245 {
  height: 245px; }

.width-246 {
  width: 246px; }

.height-246 {
  height: 246px; }

.width-247 {
  width: 247px; }

.height-247 {
  height: 247px; }

.width-248 {
  width: 248px; }

.height-248 {
  height: 248px; }

.width-249 {
  width: 249px; }

.height-249 {
  height: 249px; }

.width-250 {
  width: 250px; }

.height-250 {
  height: 250px; }

.width-251 {
  width: 251px; }

.height-251 {
  height: 251px; }

.width-252 {
  width: 252px; }

.height-252 {
  height: 252px; }

.width-253 {
  width: 253px; }

.height-253 {
  height: 253px; }

.width-254 {
  width: 254px; }

.height-254 {
  height: 254px; }

.width-255 {
  width: 255px; }

.height-255 {
  height: 255px; }

.width-256 {
  width: 256px; }

.height-256 {
  height: 256px; }

.width-257 {
  width: 257px; }

.height-257 {
  height: 257px; }

.width-258 {
  width: 258px; }

.height-258 {
  height: 258px; }

.width-259 {
  width: 259px; }

.height-259 {
  height: 259px; }

.width-260 {
  width: 260px; }

.height-260 {
  height: 260px; }

.width-261 {
  width: 261px; }

.height-261 {
  height: 261px; }

.width-262 {
  width: 262px; }

.height-262 {
  height: 262px; }

.width-263 {
  width: 263px; }

.height-263 {
  height: 263px; }

.width-264 {
  width: 264px; }

.height-264 {
  height: 264px; }

.width-265 {
  width: 265px; }

.height-265 {
  height: 265px; }

.width-266 {
  width: 266px; }

.height-266 {
  height: 266px; }

.width-267 {
  width: 267px; }

.height-267 {
  height: 267px; }

.width-268 {
  width: 268px; }

.height-268 {
  height: 268px; }

.width-269 {
  width: 269px; }

.height-269 {
  height: 269px; }

.width-270 {
  width: 270px; }

.height-270 {
  height: 270px; }

.width-271 {
  width: 271px; }

.height-271 {
  height: 271px; }

.width-272 {
  width: 272px; }

.height-272 {
  height: 272px; }

.width-273 {
  width: 273px; }

.height-273 {
  height: 273px; }

.width-274 {
  width: 274px; }

.height-274 {
  height: 274px; }

.width-275 {
  width: 275px; }

.height-275 {
  height: 275px; }

.width-276 {
  width: 276px; }

.height-276 {
  height: 276px; }

.width-277 {
  width: 277px; }

.height-277 {
  height: 277px; }

.width-278 {
  width: 278px; }

.height-278 {
  height: 278px; }

.width-279 {
  width: 279px; }

.height-279 {
  height: 279px; }

.width-280 {
  width: 280px; }

.height-280 {
  height: 280px; }

.width-281 {
  width: 281px; }

.height-281 {
  height: 281px; }

.width-282 {
  width: 282px; }

.height-282 {
  height: 282px; }

.width-283 {
  width: 283px; }

.height-283 {
  height: 283px; }

.width-284 {
  width: 284px; }

.height-284 {
  height: 284px; }

.width-285 {
  width: 285px; }

.height-285 {
  height: 285px; }

.width-286 {
  width: 286px; }

.height-286 {
  height: 286px; }

.width-287 {
  width: 287px; }

.height-287 {
  height: 287px; }

.width-288 {
  width: 288px; }

.height-288 {
  height: 288px; }

.width-289 {
  width: 289px; }

.height-289 {
  height: 289px; }

.width-290 {
  width: 290px; }

.height-290 {
  height: 290px; }

.width-291 {
  width: 291px; }

.height-291 {
  height: 291px; }

.width-292 {
  width: 292px; }

.height-292 {
  height: 292px; }

.width-293 {
  width: 293px; }

.height-293 {
  height: 293px; }

.width-294 {
  width: 294px; }

.height-294 {
  height: 294px; }

.width-295 {
  width: 295px; }

.height-295 {
  height: 295px; }

.width-296 {
  width: 296px; }

.height-296 {
  height: 296px; }

.width-297 {
  width: 297px; }

.height-297 {
  height: 297px; }

.width-298 {
  width: 298px; }

.height-298 {
  height: 298px; }

.width-299 {
  width: 299px; }

.height-299 {
  height: 299px; }

.width-300 {
  width: 300px; }

.height-300 {
  height: 300px; }

.width-301 {
  width: 301px; }

.height-301 {
  height: 301px; }

.width-302 {
  width: 302px; }

.height-302 {
  height: 302px; }

.width-303 {
  width: 303px; }

.height-303 {
  height: 303px; }

.width-304 {
  width: 304px; }

.height-304 {
  height: 304px; }

.width-305 {
  width: 305px; }

.height-305 {
  height: 305px; }

.width-306 {
  width: 306px; }

.height-306 {
  height: 306px; }

.width-307 {
  width: 307px; }

.height-307 {
  height: 307px; }

.width-308 {
  width: 308px; }

.height-308 {
  height: 308px; }

.width-309 {
  width: 309px; }

.height-309 {
  height: 309px; }

.width-310 {
  width: 310px; }

.height-310 {
  height: 310px; }

.width-311 {
  width: 311px; }

.height-311 {
  height: 311px; }

.width-312 {
  width: 312px; }

.height-312 {
  height: 312px; }

.width-313 {
  width: 313px; }

.height-313 {
  height: 313px; }

.width-314 {
  width: 314px; }

.height-314 {
  height: 314px; }

.width-315 {
  width: 315px; }

.height-315 {
  height: 315px; }

.width-316 {
  width: 316px; }

.height-316 {
  height: 316px; }

.width-317 {
  width: 317px; }

.height-317 {
  height: 317px; }

.width-318 {
  width: 318px; }

.height-318 {
  height: 318px; }

.width-319 {
  width: 319px; }

.height-319 {
  height: 319px; }

.width-320 {
  width: 320px; }

.height-320 {
  height: 320px; }

.width-321 {
  width: 321px; }

.height-321 {
  height: 321px; }

.width-322 {
  width: 322px; }

.height-322 {
  height: 322px; }

.width-323 {
  width: 323px; }

.height-323 {
  height: 323px; }

.width-324 {
  width: 324px; }

.height-324 {
  height: 324px; }

.width-325 {
  width: 325px; }

.height-325 {
  height: 325px; }

.width-326 {
  width: 326px; }

.height-326 {
  height: 326px; }

.width-327 {
  width: 327px; }

.height-327 {
  height: 327px; }

.width-328 {
  width: 328px; }

.height-328 {
  height: 328px; }

.width-329 {
  width: 329px; }

.height-329 {
  height: 329px; }

.width-330 {
  width: 330px; }

.height-330 {
  height: 330px; }

.width-331 {
  width: 331px; }

.height-331 {
  height: 331px; }

.width-332 {
  width: 332px; }

.height-332 {
  height: 332px; }

.width-333 {
  width: 333px; }

.height-333 {
  height: 333px; }

.width-334 {
  width: 334px; }

.height-334 {
  height: 334px; }

.width-335 {
  width: 335px; }

.height-335 {
  height: 335px; }

.width-336 {
  width: 336px; }

.height-336 {
  height: 336px; }

.width-337 {
  width: 337px; }

.height-337 {
  height: 337px; }

.width-338 {
  width: 338px; }

.height-338 {
  height: 338px; }

.width-339 {
  width: 339px; }

.height-339 {
  height: 339px; }

.width-340 {
  width: 340px; }

.height-340 {
  height: 340px; }

.width-341 {
  width: 341px; }

.height-341 {
  height: 341px; }

.width-342 {
  width: 342px; }

.height-342 {
  height: 342px; }

.width-343 {
  width: 343px; }

.height-343 {
  height: 343px; }

.width-344 {
  width: 344px; }

.height-344 {
  height: 344px; }

.width-345 {
  width: 345px; }

.height-345 {
  height: 345px; }

.width-346 {
  width: 346px; }

.height-346 {
  height: 346px; }

.width-347 {
  width: 347px; }

.height-347 {
  height: 347px; }

.width-348 {
  width: 348px; }

.height-348 {
  height: 348px; }

.width-349 {
  width: 349px; }

.height-349 {
  height: 349px; }

.width-350 {
  width: 350px; }

.height-350 {
  height: 350px; }

.width-351 {
  width: 351px; }

.height-351 {
  height: 351px; }

.width-352 {
  width: 352px; }

.height-352 {
  height: 352px; }

.width-353 {
  width: 353px; }

.height-353 {
  height: 353px; }

.width-354 {
  width: 354px; }

.height-354 {
  height: 354px; }

.width-355 {
  width: 355px; }

.height-355 {
  height: 355px; }

.width-356 {
  width: 356px; }

.height-356 {
  height: 356px; }

.width-357 {
  width: 357px; }

.height-357 {
  height: 357px; }

.width-358 {
  width: 358px; }

.height-358 {
  height: 358px; }

.width-359 {
  width: 359px; }

.height-359 {
  height: 359px; }

.width-360 {
  width: 360px; }

.height-360 {
  height: 360px; }

.width-361 {
  width: 361px; }

.height-361 {
  height: 361px; }

.width-362 {
  width: 362px; }

.height-362 {
  height: 362px; }

.width-363 {
  width: 363px; }

.height-363 {
  height: 363px; }

.width-364 {
  width: 364px; }

.height-364 {
  height: 364px; }

.width-365 {
  width: 365px; }

.height-365 {
  height: 365px; }

.width-366 {
  width: 366px; }

.height-366 {
  height: 366px; }

.width-367 {
  width: 367px; }

.height-367 {
  height: 367px; }

.width-368 {
  width: 368px; }

.height-368 {
  height: 368px; }

.width-369 {
  width: 369px; }

.height-369 {
  height: 369px; }

.width-370 {
  width: 370px; }

.height-370 {
  height: 370px; }

.width-371 {
  width: 371px; }

.height-371 {
  height: 371px; }

.width-372 {
  width: 372px; }

.height-372 {
  height: 372px; }

.width-373 {
  width: 373px; }

.height-373 {
  height: 373px; }

.width-374 {
  width: 374px; }

.height-374 {
  height: 374px; }

.width-375 {
  width: 375px; }

.height-375 {
  height: 375px; }

.width-376 {
  width: 376px; }

.height-376 {
  height: 376px; }

.width-377 {
  width: 377px; }

.height-377 {
  height: 377px; }

.width-378 {
  width: 378px; }

.height-378 {
  height: 378px; }

.width-379 {
  width: 379px; }

.height-379 {
  height: 379px; }

.width-380 {
  width: 380px; }

.height-380 {
  height: 380px; }

.width-381 {
  width: 381px; }

.height-381 {
  height: 381px; }

.width-382 {
  width: 382px; }

.height-382 {
  height: 382px; }

.width-383 {
  width: 383px; }

.height-383 {
  height: 383px; }

.width-384 {
  width: 384px; }

.height-384 {
  height: 384px; }

.width-385 {
  width: 385px; }

.height-385 {
  height: 385px; }

.width-386 {
  width: 386px; }

.height-386 {
  height: 386px; }

.width-387 {
  width: 387px; }

.height-387 {
  height: 387px; }

.width-388 {
  width: 388px; }

.height-388 {
  height: 388px; }

.width-389 {
  width: 389px; }

.height-389 {
  height: 389px; }

.width-390 {
  width: 390px; }

.height-390 {
  height: 390px; }

.width-391 {
  width: 391px; }

.height-391 {
  height: 391px; }

.width-392 {
  width: 392px; }

.height-392 {
  height: 392px; }

.width-393 {
  width: 393px; }

.height-393 {
  height: 393px; }

.width-394 {
  width: 394px; }

.height-394 {
  height: 394px; }

.width-395 {
  width: 395px; }

.height-395 {
  height: 395px; }

.width-396 {
  width: 396px; }

.height-396 {
  height: 396px; }

.width-397 {
  width: 397px; }

.height-397 {
  height: 397px; }

.width-398 {
  width: 398px; }

.height-398 {
  height: 398px; }

.width-399 {
  width: 399px; }

.height-399 {
  height: 399px; }

.width-400 {
  width: 400px; }

.height-400 {
  height: 400px; }

.width-401 {
  width: 401px; }

.height-401 {
  height: 401px; }

.width-402 {
  width: 402px; }

.height-402 {
  height: 402px; }

.width-403 {
  width: 403px; }

.height-403 {
  height: 403px; }

.width-404 {
  width: 404px; }

.height-404 {
  height: 404px; }

.width-405 {
  width: 405px; }

.height-405 {
  height: 405px; }

.width-406 {
  width: 406px; }

.height-406 {
  height: 406px; }

.width-407 {
  width: 407px; }

.height-407 {
  height: 407px; }

.width-408 {
  width: 408px; }

.height-408 {
  height: 408px; }

.width-409 {
  width: 409px; }

.height-409 {
  height: 409px; }

.width-410 {
  width: 410px; }

.height-410 {
  height: 410px; }

.width-411 {
  width: 411px; }

.height-411 {
  height: 411px; }

.width-412 {
  width: 412px; }

.height-412 {
  height: 412px; }

.width-413 {
  width: 413px; }

.height-413 {
  height: 413px; }

.width-414 {
  width: 414px; }

.height-414 {
  height: 414px; }

.width-415 {
  width: 415px; }

.height-415 {
  height: 415px; }

.width-416 {
  width: 416px; }

.height-416 {
  height: 416px; }

.width-417 {
  width: 417px; }

.height-417 {
  height: 417px; }

.width-418 {
  width: 418px; }

.height-418 {
  height: 418px; }

.width-419 {
  width: 419px; }

.height-419 {
  height: 419px; }

.width-420 {
  width: 420px; }

.height-420 {
  height: 420px; }

.width-421 {
  width: 421px; }

.height-421 {
  height: 421px; }

.width-422 {
  width: 422px; }

.height-422 {
  height: 422px; }

.width-423 {
  width: 423px; }

.height-423 {
  height: 423px; }

.width-424 {
  width: 424px; }

.height-424 {
  height: 424px; }

.width-425 {
  width: 425px; }

.height-425 {
  height: 425px; }

.width-426 {
  width: 426px; }

.height-426 {
  height: 426px; }

.width-427 {
  width: 427px; }

.height-427 {
  height: 427px; }

.width-428 {
  width: 428px; }

.height-428 {
  height: 428px; }

.width-429 {
  width: 429px; }

.height-429 {
  height: 429px; }

.width-430 {
  width: 430px; }

.height-430 {
  height: 430px; }

.width-431 {
  width: 431px; }

.height-431 {
  height: 431px; }

.width-432 {
  width: 432px; }

.height-432 {
  height: 432px; }

.width-433 {
  width: 433px; }

.height-433 {
  height: 433px; }

.width-434 {
  width: 434px; }

.height-434 {
  height: 434px; }

.width-435 {
  width: 435px; }

.height-435 {
  height: 435px; }

.width-436 {
  width: 436px; }

.height-436 {
  height: 436px; }

.width-437 {
  width: 437px; }

.height-437 {
  height: 437px; }

.width-438 {
  width: 438px; }

.height-438 {
  height: 438px; }

.width-439 {
  width: 439px; }

.height-439 {
  height: 439px; }

.width-440 {
  width: 440px; }

.height-440 {
  height: 440px; }

.width-441 {
  width: 441px; }

.height-441 {
  height: 441px; }

.width-442 {
  width: 442px; }

.height-442 {
  height: 442px; }

.width-443 {
  width: 443px; }

.height-443 {
  height: 443px; }

.width-444 {
  width: 444px; }

.height-444 {
  height: 444px; }

.width-445 {
  width: 445px; }

.height-445 {
  height: 445px; }

.width-446 {
  width: 446px; }

.height-446 {
  height: 446px; }

.width-447 {
  width: 447px; }

.height-447 {
  height: 447px; }

.width-448 {
  width: 448px; }

.height-448 {
  height: 448px; }

.width-449 {
  width: 449px; }

.height-449 {
  height: 449px; }

.width-450 {
  width: 450px; }

.height-450 {
  height: 450px; }

.width-451 {
  width: 451px; }

.height-451 {
  height: 451px; }

.width-452 {
  width: 452px; }

.height-452 {
  height: 452px; }

.width-453 {
  width: 453px; }

.height-453 {
  height: 453px; }

.width-454 {
  width: 454px; }

.height-454 {
  height: 454px; }

.width-455 {
  width: 455px; }

.height-455 {
  height: 455px; }

.width-456 {
  width: 456px; }

.height-456 {
  height: 456px; }

.width-457 {
  width: 457px; }

.height-457 {
  height: 457px; }

.width-458 {
  width: 458px; }

.height-458 {
  height: 458px; }

.width-459 {
  width: 459px; }

.height-459 {
  height: 459px; }

.width-460 {
  width: 460px; }

.height-460 {
  height: 460px; }

.width-461 {
  width: 461px; }

.height-461 {
  height: 461px; }

.width-462 {
  width: 462px; }

.height-462 {
  height: 462px; }

.width-463 {
  width: 463px; }

.height-463 {
  height: 463px; }

.width-464 {
  width: 464px; }

.height-464 {
  height: 464px; }

.width-465 {
  width: 465px; }

.height-465 {
  height: 465px; }

.width-466 {
  width: 466px; }

.height-466 {
  height: 466px; }

.width-467 {
  width: 467px; }

.height-467 {
  height: 467px; }

.width-468 {
  width: 468px; }

.height-468 {
  height: 468px; }

.width-469 {
  width: 469px; }

.height-469 {
  height: 469px; }

.width-470 {
  width: 470px; }

.height-470 {
  height: 470px; }

.width-471 {
  width: 471px; }

.height-471 {
  height: 471px; }

.width-472 {
  width: 472px; }

.height-472 {
  height: 472px; }

.width-473 {
  width: 473px; }

.height-473 {
  height: 473px; }

.width-474 {
  width: 474px; }

.height-474 {
  height: 474px; }

.width-475 {
  width: 475px; }

.height-475 {
  height: 475px; }

.width-476 {
  width: 476px; }

.height-476 {
  height: 476px; }

.width-477 {
  width: 477px; }

.height-477 {
  height: 477px; }

.width-478 {
  width: 478px; }

.height-478 {
  height: 478px; }

.width-479 {
  width: 479px; }

.height-479 {
  height: 479px; }

.width-480 {
  width: 480px; }

.height-480 {
  height: 480px; }

.width-481 {
  width: 481px; }

.height-481 {
  height: 481px; }

.width-482 {
  width: 482px; }

.height-482 {
  height: 482px; }

.width-483 {
  width: 483px; }

.height-483 {
  height: 483px; }

.width-484 {
  width: 484px; }

.height-484 {
  height: 484px; }

.width-485 {
  width: 485px; }

.height-485 {
  height: 485px; }

.width-486 {
  width: 486px; }

.height-486 {
  height: 486px; }

.width-487 {
  width: 487px; }

.height-487 {
  height: 487px; }

.width-488 {
  width: 488px; }

.height-488 {
  height: 488px; }

.width-489 {
  width: 489px; }

.height-489 {
  height: 489px; }

.width-490 {
  width: 490px; }

.height-490 {
  height: 490px; }

.width-491 {
  width: 491px; }

.height-491 {
  height: 491px; }

.width-492 {
  width: 492px; }

.height-492 {
  height: 492px; }

.width-493 {
  width: 493px; }

.height-493 {
  height: 493px; }

.width-494 {
  width: 494px; }

.height-494 {
  height: 494px; }

.width-495 {
  width: 495px; }

.height-495 {
  height: 495px; }

.width-496 {
  width: 496px; }

.height-496 {
  height: 496px; }

.width-497 {
  width: 497px; }

.height-497 {
  height: 497px; }

.width-498 {
  width: 498px; }

.height-498 {
  height: 498px; }

.width-499 {
  width: 499px; }

.height-499 {
  height: 499px; }

.width-500 {
  width: 500px; }

.height-500 {
  height: 500px; }

.w-1 {
  width: 1% !important; }

.h-1 {
  height: 1% !important; }

.w-2 {
  width: 2% !important; }

.h-2 {
  height: 2% !important; }

.w-3 {
  width: 3% !important; }

.h-3 {
  height: 3% !important; }

.w-4 {
  width: 4% !important; }

.h-4 {
  height: 4% !important; }

.w-5 {
  width: 5% !important; }

.h-5 {
  height: 5% !important; }

.w-6 {
  width: 6% !important; }

.h-6 {
  height: 6% !important; }

.w-7 {
  width: 7% !important; }

.h-7 {
  height: 7% !important; }

.w-8 {
  width: 8% !important; }

.h-8 {
  height: 8% !important; }

.w-9 {
  width: 9% !important; }

.h-9 {
  height: 9% !important; }

.w-10 {
  width: 10% !important; }

.h-10 {
  height: 10% !important; }

.w-11 {
  width: 11% !important; }

.h-11 {
  height: 11% !important; }

.w-12 {
  width: 12% !important; }

.h-12 {
  height: 12% !important; }

.w-13 {
  width: 13% !important; }

.h-13 {
  height: 13% !important; }

.w-14 {
  width: 14% !important; }

.h-14 {
  height: 14% !important; }

.w-15 {
  width: 15% !important; }

.h-15 {
  height: 15% !important; }

.w-16 {
  width: 16% !important; }

.h-16 {
  height: 16% !important; }

.w-17 {
  width: 17% !important; }

.h-17 {
  height: 17% !important; }

.w-18 {
  width: 18% !important; }

.h-18 {
  height: 18% !important; }

.w-19 {
  width: 19% !important; }

.h-19 {
  height: 19% !important; }

.w-20 {
  width: 20% !important; }

.h-20 {
  height: 20% !important; }

.w-21 {
  width: 21% !important; }

.h-21 {
  height: 21% !important; }

.w-22 {
  width: 22% !important; }

.h-22 {
  height: 22% !important; }

.w-23 {
  width: 23% !important; }

.h-23 {
  height: 23% !important; }

.w-24 {
  width: 24% !important; }

.h-24 {
  height: 24% !important; }

.w-25 {
  width: 25% !important; }

.h-25 {
  height: 25% !important; }

.w-26 {
  width: 26% !important; }

.h-26 {
  height: 26% !important; }

.w-27 {
  width: 27% !important; }

.h-27 {
  height: 27% !important; }

.w-28 {
  width: 28% !important; }

.h-28 {
  height: 28% !important; }

.w-29 {
  width: 29% !important; }

.h-29 {
  height: 29% !important; }

.w-30 {
  width: 30% !important; }

.h-30 {
  height: 30% !important; }

.w-31 {
  width: 31% !important; }

.h-31 {
  height: 31% !important; }

.w-32 {
  width: 32% !important; }

.h-32 {
  height: 32% !important; }

.w-33 {
  width: 33% !important; }

.h-33 {
  height: 33% !important; }

.w-34 {
  width: 34% !important; }

.h-34 {
  height: 34% !important; }

.w-35 {
  width: 35% !important; }

.h-35 {
  height: 35% !important; }

.w-36 {
  width: 36% !important; }

.h-36 {
  height: 36% !important; }

.w-37 {
  width: 37% !important; }

.h-37 {
  height: 37% !important; }

.w-38 {
  width: 38% !important; }

.h-38 {
  height: 38% !important; }

.w-39 {
  width: 39% !important; }

.h-39 {
  height: 39% !important; }

.w-40 {
  width: 40% !important; }

.h-40 {
  height: 40% !important; }

.w-41 {
  width: 41% !important; }

.h-41 {
  height: 41% !important; }

.w-42 {
  width: 42% !important; }

.h-42 {
  height: 42% !important; }

.w-43 {
  width: 43% !important; }

.h-43 {
  height: 43% !important; }

.w-44 {
  width: 44% !important; }

.h-44 {
  height: 44% !important; }

.w-45 {
  width: 45% !important; }

.h-45 {
  height: 45% !important; }

.w-46 {
  width: 46% !important; }

.h-46 {
  height: 46% !important; }

.w-47 {
  width: 47% !important; }

.h-47 {
  height: 47% !important; }

.w-48 {
  width: 48% !important; }

.h-48 {
  height: 48% !important; }

.w-49 {
  width: 49% !important; }

.h-49 {
  height: 49% !important; }

.w-50 {
  width: 50% !important; }

.h-50 {
  height: 50% !important; }

.w-51 {
  width: 51% !important; }

.h-51 {
  height: 51% !important; }

.w-52 {
  width: 52% !important; }

.h-52 {
  height: 52% !important; }

.w-53 {
  width: 53% !important; }

.h-53 {
  height: 53% !important; }

.w-54 {
  width: 54% !important; }

.h-54 {
  height: 54% !important; }

.w-55 {
  width: 55% !important; }

.h-55 {
  height: 55% !important; }

.w-56 {
  width: 56% !important; }

.h-56 {
  height: 56% !important; }

.w-57 {
  width: 57% !important; }

.h-57 {
  height: 57% !important; }

.w-58 {
  width: 58% !important; }

.h-58 {
  height: 58% !important; }

.w-59 {
  width: 59% !important; }

.h-59 {
  height: 59% !important; }

.w-60 {
  width: 60% !important; }

.h-60 {
  height: 60% !important; }

.w-61 {
  width: 61% !important; }

.h-61 {
  height: 61% !important; }

.w-62 {
  width: 62% !important; }

.h-62 {
  height: 62% !important; }

.w-63 {
  width: 63% !important; }

.h-63 {
  height: 63% !important; }

.w-64 {
  width: 64% !important; }

.h-64 {
  height: 64% !important; }

.w-65 {
  width: 65% !important; }

.h-65 {
  height: 65% !important; }

.w-66 {
  width: 66% !important; }

.h-66 {
  height: 66% !important; }

.w-67 {
  width: 67% !important; }

.h-67 {
  height: 67% !important; }

.w-68 {
  width: 68% !important; }

.h-68 {
  height: 68% !important; }

.w-69 {
  width: 69% !important; }

.h-69 {
  height: 69% !important; }

.w-70 {
  width: 70% !important; }

.h-70 {
  height: 70% !important; }

.w-71 {
  width: 71% !important; }

.h-71 {
  height: 71% !important; }

.w-72 {
  width: 72% !important; }

.h-72 {
  height: 72% !important; }

.w-73 {
  width: 73% !important; }

.h-73 {
  height: 73% !important; }

.w-74 {
  width: 74% !important; }

.h-74 {
  height: 74% !important; }

.w-75 {
  width: 75% !important; }

.h-75 {
  height: 75% !important; }

.w-76 {
  width: 76% !important; }

.h-76 {
  height: 76% !important; }

.w-77 {
  width: 77% !important; }

.h-77 {
  height: 77% !important; }

.w-78 {
  width: 78% !important; }

.h-78 {
  height: 78% !important; }

.w-79 {
  width: 79% !important; }

.h-79 {
  height: 79% !important; }

.w-80 {
  width: 80% !important; }

.h-80 {
  height: 80% !important; }

.w-81 {
  width: 81% !important; }

.h-81 {
  height: 81% !important; }

.w-82 {
  width: 82% !important; }

.h-82 {
  height: 82% !important; }

.w-83 {
  width: 83% !important; }

.h-83 {
  height: 83% !important; }

.w-84 {
  width: 84% !important; }

.h-84 {
  height: 84% !important; }

.w-85 {
  width: 85% !important; }

.h-85 {
  height: 85% !important; }

.w-86 {
  width: 86% !important; }

.h-86 {
  height: 86% !important; }

.w-87 {
  width: 87% !important; }

.h-87 {
  height: 87% !important; }

.w-88 {
  width: 88% !important; }

.h-88 {
  height: 88% !important; }

.w-89 {
  width: 89% !important; }

.h-89 {
  height: 89% !important; }

.w-90 {
  width: 90% !important; }

.h-90 {
  height: 90% !important; }

.w-91 {
  width: 91% !important; }

.h-91 {
  height: 91% !important; }

.w-92 {
  width: 92% !important; }

.h-92 {
  height: 92% !important; }

.w-93 {
  width: 93% !important; }

.h-93 {
  height: 93% !important; }

.w-94 {
  width: 94% !important; }

.h-94 {
  height: 94% !important; }

.w-95 {
  width: 95% !important; }

.h-95 {
  height: 95% !important; }

.w-96 {
  width: 96% !important; }

.h-96 {
  height: 96% !important; }

.w-97 {
  width: 97% !important; }

.h-97 {
  height: 97% !important; }

.w-98 {
  width: 98% !important; }

.h-98 {
  height: 98% !important; }

.w-99 {
  width: 99% !important; }

.h-99 {
  height: 99% !important; }

.w-100 {
  width: 100% !important; }

.h-100 {
  height: 100% !important; }

.bg-gradient-primary-left-right {
  background-image: linear-gradient(to right, #14E8D5, #597EE8) !important; }

.bg-gradient-primary-top-left-bottom-right {
  background-image: linear-gradient(to bottom right, #14E8D5, #597EE8) !important; }

.bg-gradient-secondary-left-right {
  background-image: linear-gradient(to right, #B06AB3, #4568DC) !important; }

.bg-gradient-secondary-top-left-bottom-right {
  background-image: linear-gradient(to bottom right, #4568DC, #B06AB3) !important; }

.bg-primary {
  background-color: #3fa7e1 !important; }

.bg-primary-fade {
  background-color: #ddecf8 !important; }

.bg-secondary {
  background-color: #A96AB5 !important; }

.bg-success {
  background-color: #45d386 !important; }

.bg-danger {
  background-color: #f7d7eb !important; }

.box-shadow {
  box-shadow: 0 2px 4px 0 rgba(38, 35, 66, 0.25) !important; }

.flex-auto {
  -webkit-flex: auto;
          flex: auto; }

.line-height-unset {
  line-height: unset !important; }

.text-underline {
  text-decoration: underline; }

.pointer-events-none {
  pointer-events: none !important; }

.border-unset {
  border: unset !important; }

/* 35.Loading */
.loading-overlay {
  z-index: 1050;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%; }

.loading {
  display: inline-block;
  width: 30px;
  height: 30px;
  border: 2px solid rgba(63, 167, 225, 0.2);
  border-radius: 50%;
  border-top-color: #3fa7e1;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
  left: calc(50% - 15px);
  top: calc(50% - 15px);
  position: fixed;
  z-index: 999999999999999; }

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg); } }

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg); } }

.disabled {
  pointer-events: none; }

.navbar .custom-navbar-brand {
  margin: 0rem;
  padding: 0rem;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  position: relative; }
  .navbar .custom-navbar-brand .custom-navbar-brand {
    position: absolute;
    width: 200px !important;
    margin-top: 30px; }

.custom-col {
  padding-bottom: 40px !important; }
  .custom-col h6 {
    margin-top: 10px; }
  .custom-col .ant-checkbox-wrapper {
    margin-top: 10px; }
  .custom-col .ant-btn {
    color: #fff !important;
    background-color: #db0d4c !important;
    text-align: center !important;
    padding: 12px !important; }

.navbar {
  height: auto;
  padding: 1rem; }
  .navbar .navbar-brand {
    margin: 0rem;
    padding: 0rem;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-end;
            align-items: flex-end; }
    .navbar .navbar-brand .navbar-brand-logo {
      width: 100px;
      padding-right: 0.5rem; }
    .navbar .navbar-brand .navbar-brand-name {
      font-weight: 500;
      color: #343a40; }
  .navbar .navbar-collapse .navbar-nav {
    display: -webkit-flex;
    display: flex;
    margin-left: auto;
    -webkit-align-items: center;
            align-items: center; }
    .navbar .navbar-collapse .navbar-nav .nav-item {
      font-weight: 500;
      padding: 0.5rem 1rem;
      text-transform: uppercase; }
      .navbar .navbar-collapse .navbar-nav .nav-item a {
        color: #343a40; }
        .navbar .navbar-collapse .navbar-nav .nav-item a.active {
          color: #3FA7E1; }
    @media (max-width: 991px) {
      .navbar .navbar-collapse .navbar-nav {
        -webkit-align-items: flex-start;
                align-items: flex-start; }
        .navbar .navbar-collapse .navbar-nav .nav-item {
          padding: 0.75rem 0rem 0rem 0rem !important; } }

.header-menu-dropdown .ant-dropdown-menu {
  padding: 0;
  width: 300px;
  overflow: hidden;
  margin-top: 0.5rem;
  border-radius: 0.5rem;
  background-image: linear-gradient(to right, #14E8D5, #597EE8) !important; }
  .header-menu-dropdown .ant-dropdown-menu .ant-dropdown-menu-item {
    color: #FFFFFF;
    padding: 0.25rem 1rem;
    transition: all ease-in-out 0.25s; }
    .header-menu-dropdown .ant-dropdown-menu .ant-dropdown-menu-item a {
      color: #FFFFFF; }
    .header-menu-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:hover,
    .header-menu-dropdown .ant-dropdown-menu .ant-dropdown-menu-item a:hover {
      color: #3FA7E1; }
    .header-menu-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:last-child {
      padding-bottom: 0.5rem; }

.ant-dropdown-trigger {
  transition: all ease-in-out 0.25s; }
  .ant-dropdown-trigger.ant-dropdown-open {
    color: #FFFFFF !important;
    transition: all ease-in-out 0.25s;
    background-image: linear-gradient(to right, #14E8D5, #597EE8) !important; }

button:focus {
  outline: unset; }

